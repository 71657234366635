import React from "react"

import { graphql } from "gatsby"
import styled from "styled-components"

import { Stack } from "@kiwicom/orbit-components/lib/"

import Container from "components/container"
import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const TextContent = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }
  h3 {
    font-size: 17px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.4;
    color: ${(props) => props.theme.orbit.paletteProductDark};
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
  a:hover {
    color: ${(props) => props.theme.orbit.paletteProductDark};
  }
  p {
    line-height: 1.55;
  }
  li {
    margin-bottom: 10px;
  }
  *:last-child {
    margin-bottom: 0px;
  }
  *:first-child {
    margin-top: 0px;
  }
`

const Page = ({ data }) => {
  const update = data.companyUpdatesYaml
  return (
    <DriveLayout
      title={
        update.title + (update.title?.endsWith(" Update") ? "" : " Update")
      }
    >
      <Hero
        title={`${update.title}`}
        subtitle={`Posted ${update.published_date}`}
      />
      <Container size="medium">
        <Stack spacing="XLarge">
          <EmberCard>
            <EmberCardSection>
              <TextContent>
                <div dangerouslySetInnerHTML={{ __html: update.content }} />
              </TextContent>
            </EmberCardSection>
          </EmberCard>
          {update.feedback && (
            <EmberCardGroup sectionTitle="Feedback Form Responses">
              <EmberCard>
                {update.feedback.map((item, index) => (
                  <EmberCardSection>
                    <TextContent>
                      <h2>Question {index + 1}</h2>
                      <h3>{item.question}</h3>
                      <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </TextContent>
                  </EmberCardSection>
                ))}
              </EmberCard>
            </EmberCardGroup>
          )}
        </Stack>
      </Container>
    </DriveLayout>
  )
}

export const query = graphql`
  query ($id: String) {
    companyUpdatesYaml(id: { eq: $id }) {
      title
      published_date(formatString: "D MMM YYYY")
      content
      feedback {
        question
        answer
      }
    }
  }
`

export default Page
